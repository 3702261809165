// 
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

.bg-pattern {
    background-image: url("../images/bg-pattern-2.png");
    background-size: cover;
}

// authentication pages background
body.authentication-bg {
    background-color: $gray-200;
    background-size: cover;
    background-position: center;
}

body.authentication-bg-pattern {
    background-image: url("../images/bg-pattern.png");
    background-color: $primary;
}

// Logout page
.logout-icon {
    width: 140px;
}